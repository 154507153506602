import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import {
    GetCommercialPartnersDataParams,
    GetCommercialPartnersDataResponse,
} from '../../core/types/dto/partner/get-commercial-partner-data'
import { GetComercialPartnerList } from '../../core/types/dto/partner/partner-list'
import { HttpClientOptionsService } from '../../legacy/authentication/services/http-client-options.service'

@Injectable({ providedIn: 'root' })
export class PartnersDataService {
    constructor(
        private httpClient: HttpClient,
        private httpClientOptions: HttpClientOptionsService,
    ) {}

    getCommercialPartnersData(body: GetCommercialPartnersDataParams) {
        return this.httpClient.post<GetCommercialPartnersDataResponse>(
            `${environment.URL_BASE}/partnerhub/commercial-partner/get-commercial-partners-data`,
            body,
            this.httpClientOptions.getPartnerhubAuthorization(),
        )
    }

    getHubPartnersList() {
        const url = `${environment.URL_BASE}/partnerhub/commercial-partner/get-commercial-partners-list`
        const options = this.httpClientOptions.getPartnerhubAuthorization()
        return this.httpClient.get<GetComercialPartnerList>(url, options)
    }
}
